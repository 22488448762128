<template>
  <main>
    <a class="whatsappWrapper" href="https://wa.me/+917579175791?text=I'm%20inquiring%20about%20the%20properties%20from%20Website!"><img src="../assets/img/WhatsApp.svg"/></a>
    <user-information />
    <div class="on-site">
      <h2>FEATURED AMENITIES ON-SITE</h2>
      <div class="grid-view">
        <div>
          <img src="../assets/img/icons_featured_aminities/sustainability.svg" alt="Sustainability">
          Sustainability 
        </div>
        <div>
          <img src="../assets/img/icons_featured_aminities/restaurant.svg" alt="restaurant">
          Restaurant
        </div>
        <div>
          <img src="../assets/img/icons_featured_aminities/spa.svg" alt="spa">
          Spa
        </div>
        <div>
          <img src="../assets/img/icons_featured_aminities/dumbbell.svg" alt="dumbbell">
          Fitness Center
        </div>
        <div>
          <img src="../assets/img/icons_featured_aminities/pool.svg" alt="pool">
          Outdoor Pool
        </div>
        <div>
            <img src="../assets/img/icons_featured_aminities/24.svg" alt="24 Hour Room Service">
            24 Hour Room Service
        </div>
        <div>
          <img src="../assets/img/icons_featured_aminities/housekeeping.svg" alt="Housekeeping">
          Daily Housekeeping
        </div>
        <div>
          <img src="../assets/img/icons_featured_aminities/userSpace.svg" alt="pool">
          Meeting Space
        </div>
        <div>
          <img src="../assets/img/icons_featured_aminities/service.svg" alt="service">
          Turndown Service (On request)
        </div>
        <div><img src="../assets/img/icons_featured_aminities/dryClean.svg" alt="dryClean">
          Dry Cleaning Service
        </div>
        <div>
          <img src="../assets/img/icons_featured_aminities/laundry.svg" alt="laundry">
          Laundry
        </div>
        <div>
          <img src="../assets/img/icons_featured_aminities/sloon.svg" alt="Hair salon">
          Hair salon
        </div>
        <div><img src="../assets/img/icons_featured_aminities/roomService.svg" alt="roomService">
          Room Service
        </div>
        <div>
          <img src="../assets/img/icons_featured_aminities/calls.svg" alt="calls">
          Wake up Calls
        </div>
        <div>
          <img src="../assets/img/icons_featured_aminities/wifi.svg" alt="Free Wifi">
          Free Wifi
        </div>
        <div>
          <img src="../assets/img/icons_featured_aminities/hair-dryer.svg" alt="Hairdryer">
          Hairdryer
        </div>
        <div>
          <img src="../assets/img/icons_featured_aminities/car-parking.svg" alt="Car parking">
          Car parking (at owner's risk)
        </div>
        <div>
          <img src="../assets/img/icons_featured_aminities/tcm.svg" alt="tcm">
          TCM
        </div>
        <div>
          <img src="../assets/img/icons_featured_aminities/tv.svg" alt="tv">
          Cable TV
        </div>
        <div>
            <img src="../assets/img/icons_featured_aminities/breakfast.svg" alt="breakfast">
            Complimentary breakfast
          </div>
        <div>
          <img src="../assets/img/icons_featured_aminities/breakfast.svg" alt="breakfast">
          Indian breakfast
        </div>
        <div>
          <img src="../assets/img/icons_featured_aminities/breakfast.svg" alt="breakfast">
          Continental breakfast
        </div>
        <div>
          <img src="../assets/img/icons_featured_aminities/breakfast.svg" alt="breakfast">
          American breakfast
        </div>
        <div>
          <img src="../assets/img/icons_featured_aminities/iron.svg" alt="iron">
          Iron and iron board facility
        </div>
        <div>
          <img src="../assets/img/icons_featured_aminities/slippers.svg" alt="slippers">
          Shoe horn and slippers
        </div>
        <div>
          <img src="../assets/img/icons_featured_aminities/shaving-kit.svg" alt="shaving-kit">
          Shaving kit
        </div>
        <div>
          <img src="../assets/img/icons_featured_aminities/dental.svg" alt="dental">
          Other "free" facilities like dental kit
        </div>
      </div>
    </div>
    <div
      v-for="amenities in amenityChunk"
      :key="amenities"
      class="featured-works"
    >
      <div class="container">
        <div
          v-for="amenity in amenities"
          :key="amenity"
        >
          <div class="featured-works-item">
            <img :src="amenity.image" :alt="amenity.title" class="img-cover" />
            <div class="overlay">
              <div class="content">
                <!-- <span>residenital</span><span>2019</span> -->
                <h2>{{ amenity.title }}</h2>
                <router-link :to="amenity.to">
                  <h3>Explore more</h3>
                </router-link>
              </div>
            </div>
          </div>
        </div>

        <div class="clearfix"></div>
      </div>
      <!-- /.container -->
    </div>
  </main>
  <!-- /.featured-works -->

  <!-- ========== Footer ========== -->
</template>

<script>
import UserInformation from '../components/formComponents/userInformation.vue';

export default {
  name: "Club",
  components: {UserInformation},
  data() {
    function chunk(inputArray, perChunk) {
      var result = inputArray.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / perChunk);

        if (!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = []; // start a new chunk
        }

        resultArray[chunkIndex].push(item);

        return resultArray;
      }, []);

      return result; // result: [['a','b'], ['c','d'], ['e']]
    }

    var amenities = [
		
      {
        title: "Swimming Pool",
        image: require("../assets/img/real_site_images/resized/pool_setup_cropped.jpg"),
        to: "/SwimmingPool",
      },
      {
        title: "Spa & Hammam",
        image: require("../assets/img/final_3d_images/low/img026.jpg"),
        to: "/Hammam",
      },
      {
        title: "Gym",
		image: require("../assets/img/real_site_images/resized/fitness centre_2.jpg"),
        to: "/Gymnasium",
      },
      {
        title: "Outdoor Sports",
        image: require("../assets/img/final_3d_images/low/img027.jpg"),
        to: "/OutdoorSports",
      },
      {
        title: "Indoor Sports",
        image: require("../assets/img/final_3d_images/low/img086.jpg"),
        to: "/IndoorSports",
      },
      {
        title: "Indoor Games",
        image: require("../assets/img/real_site_images/resized/Indoor_Games_1.jpg"),
        to: "/IndoorGames",
      },
      {
        title: "Experiences",
        image: require("../assets/img/real_site_images/resized/Disco.jpg"),
        to: "/Experiences",
      },
      {
        title: "Meetings & Weddings",
        image: require("../assets/img/real_site_images/resized/the ballroom_setup.jpg"),
        to: "/MeetingsWeddings",
      },
    ];

    let amenityChunk = chunk(amenities, 7);

    return { amenityChunk };
  },
};
</script>

<style scoped>

.grid-view{
  width: max-content;
  display: grid;
  grid-template-columns: repeat(3,1fr);
  justify-content: space-between;
  text-align: left;
  row-gap: 30px;
  column-gap: 40px;
  margin: 0px auto;
  margin-top: 40px;
  vertical-align: text-top;
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 700px) {
  .grid-view{
    grid-template-columns: repeat(1,1fr);
  }
}

/* Medium devices (landscape , 768px and up) */
@media only screen and (min-width: 701px) and (max-width: 1000px){
  .grid-view{
    grid-template-columns: repeat(2,1fr);
  }
}




.grid-view div img {
  height: 20px;
  width: 18px;
}

.grid-view div{
  display: flex;
  align-items: center;
  gap: 8px;
}

.featured-works-item  img {
 border-radius: 5px;
 /* box-shadow: rgba(95, 95, 95, 0.5) 0 0 15px 2px; */
 box-shadow: rgb(95 95 95 / 33%) 0 0 10px 1px;
}

.content {
  display: flex;
    justify-content: space-between;
    align-items: center;
}


.memberWrapper{
	height: 400px;
	display: grid;
	place-items: center;
}

.membertagline{
	padding: 20px;
	text-align: center;
}

.wrapperr{
	display: grid;
	place-items: initial;
}
.toMemberBtn{
	width: fit-content;
	margin: auto;
	padding:10px  20px;
	  font-weight: 100;
	  font-size: 1.5rem;
	  color: white;
	  background-color: black;
  font-family: "DM Serif Text", "sans-serif";
}


.featured-works-item .overlay {
  background-color: rgba(255, 255, 255, 0.8) !important;
  padding: 10px 20px;
  border-radius: 5px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.content h2 {color: rgba(0, 0, 0, 0.8);transition: all 0.2s ease;}

h3 {
  color: rgba(0, 0, 0,1);
  font-family: Montserrat,sans-serif;
  font-weight: 400;
  border-bottom: 1px solid rgba(0, 0, 0,0.8);
}

@media(max-width:800px) {

  .content h2 {font-size: 1rem !important;}

h3 {
  font-size: 1rem;
}

.featured-works {margin: 0;}

}

.on-site {
  text-align: center;
  margin-bottom: 50px;
}


.asd{
  display: flex;
  justify-content: center;
  gap: 50px;
  opacity: 0.7;
}
</style>
